import React from "react";

export default function SectionRenders({ cards }) {
  return (
    <div className="w-full h-full flex flex-wrap justify-start gap-4 px-2 ">
      <div className="z-1 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200  sm:mt-1 sm:pt-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {cards.map((post) => (
          <article className="flex max-w-xl flex-col items-start justify-between">
            <div className="group">
              <a href={post.url} target="_blank">
                <img
                  src={post.logo}
                  className="w-full h-40 object-cover rounded-2xl"
                />
              </a>

              <h3 className="font-poppins mt-3 text-sm font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                <a href={post.url} className="text-2xl text-base-primary">
                  {post.title}
                </a>
              </h3>
              <p className="mt-[7px] font-poppins mt-5 line-clamp-3 text-[16px] leading-6 text-1xl text-base-grey">
                {post.content}
              </p>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
}
