import { React, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import companyLogo from "../assets/companyIcon.png";
import closePng from "../assets/close.png";
import "../App.css";
import arrow from "../assets/arrow.png";
import { useMediaQuery } from "react-responsive";

export default function Home({
  setHospitalTabs,
  hospitalTabs,
  set,
  setTab,
  setEcommerceTabs,
  ecommerceTabs,
}) {
  // responsive
  const isMobile = useMediaQuery({ maxWidth: 425 });
  const isTablet = useMediaQuery({ maxWidth: 1355 });
  const isLabtop = useMediaQuery({ maxWidth: 1509 });
  const [isOpen, setIsOpen] = useState(false);
  const tabs = [
    {
      id: 0,
      name: "All",
    },
    {
      id: 1,
      name: "Hospital",
    },
    {
      id: 2,
      name: "Clinic",
    },
    {
      id: 3,
      name: "Pharma",
    },
    {
      id: 4,
      name: "Laboratory",
    },
  ];
  const ecomTab = [
    {
      id: 0,
      name: "Flutter",
    },
    {
      id: 1,
      name: "PHP",
    },
  ];

  //auto close
  useEffect(() => {
    if (!isTablet) {
      setIsOpen(false);
    }
  }, [isTablet]);

  const handleSetTab = (value) => {
    setTab(value);
  };
  const handleClick = () => {
    window.open("https://qo-box.com/contact.html", "_blank");
  };
  const handleClickHome = () => {
    window.open("https://qo-box.com/", "_blank");
  };

  return (
    <div className="flex flex-col items-start justify-start min-h-screen">
      {/* Navbar */}

      <header className="mobile-header px-[96px] flex bg-white justify-between items-center px-8 w-full">
        <a href="#">
          <img
            src={companyLogo}
            alt="Company Logo"
            className="w-32 object-contain hover:scale-105 transition-all p-[18px]"
          />
        </a>

        <ul
          className={` flex items-center gap-8 font-medium text-base ${
            isTablet && "hidden"
          } ${isLabtop && "gap-[1px]"} `}
        >
          <li
            className={`leading-[20px] p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[10px] py-[28px] font-poppins ${
              set === "hospital" &&
              "border-b-[3px] border-base-primary text-base-primary"
            } `}
          >
            <Link to="/" onClick={() => handleSetTab("hospital")}>
              Health Care
            </Link>
          </li>
          <li
            className={` p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[10px] py-[28px] font-poppins ${
              set === "fitness" &&
              "border-b-[3px] border-base-primary text-base-primary"
            } `}
          >
            <Link to="fitness" onClick={() => handleSetTab("fitness")}>
              Fitness
            </Link>
          </li>
          <li
            className={` p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[10px] py-[28px] font-poppins ${
              set === "hrms" &&
              "border-b-[3px] border-base-primary text-base-primary"
            } `}
          >
            <Link to="hrms" onClick={() => handleSetTab("hrms")}>
              Human Resources
            </Link>
          </li>
          <li
            className={` p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[10px] py-[28px] font-poppins ${
              set === "sms" &&
              "border-b-[3px] border-base-primary text-base-primary"
            } `}
          >
            <Link to="sms" onClick={() => handleSetTab("sms")}>
              School
            </Link>
          </li>
          <li
            className={` p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[10px] py-[28px] font-poppins ${
              set === "aims" &&
              "border-b-[3px] border-base-primary text-base-primary"
            } `}
          >
            <Link to="aims" onClick={() => handleSetTab("aims")}>
              Accounting / Inventory
            </Link>
          </li>
          <li
            className={` p-3 hover:bg-red gover:text-white text-base-grey  transition-all cursor-pointer px-[10px] py-[28px] font-poppins ${
              set === "ecom" &&
              "border-b-[3px] border-base-primary text-base-primary"
            } `}
          >
            <Link to="ecom" onClick={() => handleSetTab("ecom")}>
              E-Commerce
            </Link>
          </li>
        </ul>

        <div className={` flex items-center gap-2 ${isTablet && "hidden"}`}>
          <li
            className={` list-none p-3  text-base-grey  transition-all cursor-pointer px-[10px] py-[28px] font-poppins`}
          >
            <Link
              className="underline items-center flex"
              onClick={handleClickHome}
            >
              Home
              <img src={arrow} className="m-[-12px]" />
            </Link>
          </li>
          <div
            onClick={handleClick}
            className="cursor-pointer font-poppins flex items-center	 gap-2 bg-gradient-to-r from-dark-orange to-light-orange inline px-[14px] py-[8px] rounded-full text-white font-bold text-lg"
          >
            <i class="bx bx-envelope"></i>
            Contact
            <i class="bx bx-right-arrow-alt bg-white p-1 rounded-full text-black"></i>
          </div>
        </div>

        {/* MOBILE TAB MENU */}
        <div
          className={isTablet ? "showMENU" : "hidden"}
          onClick={() => setIsOpen(true)}
        >
          <i className="bx bx-menu text-5xl ml-3 text-base-grey cursor-pointer"></i>
        </div>
        {/* MOBILE SIDEBAR */}
        {isOpen && (
          <div
            onClick={() => setIsOpen(false)}
            className={
              "fixed h-full w-screen bg-black/40 backdrop-blur-1px] top-0 left-0 "
            }
          >
            <section
              className={`text-black bg-white flex-col absolute right-0 top-0 h-screen p-8 gap-8 z-50 flex ${
                isMobile ? "w-76" : "w-96"
              }`}
            >
              <img
                src={closePng}
                onClick={() => setIsOpen(false)}
                className="mt-0 mr-75mb-8 text-3xl cursor-pointer w-6 "
              />
              <ul
                className={` flex flex-col items-start font-medium text-base `}
              >
                <li
                  className={`leading-[20px] p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[5px] py-[14px] font-poppins ${
                    set === "hospital" && " text-base-primary"
                  } `}
                >
                  <Link
                    to="/"
                    onClick={() => {
                      // onClose()

                      handleSetTab("hospital");
                    }}
                  >
                    Health Care
                  </Link>
                </li>
                <li
                  className={` p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[5px] py-[14px] font-poppins ${
                    set === "fitness" && "text-base-primary"
                  } `}
                >
                  <Link to="fitness" onClick={() => handleSetTab("fitness")}>
                    Fitness
                  </Link>
                </li>
                <li
                  className={` p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[5px] py-[14px] font-poppins ${
                    set === "hrms" && " text-base-primary"
                  } `}
                >
                  <Link to="hrms" onClick={() => handleSetTab("hrms")}>
                    Human Resources
                  </Link>
                </li>
                <li
                  className={` p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[5px] py-[14px] font-poppins ${
                    set === "sms" && " text-base-primary"
                  } `}
                >
                  <Link to="sms" onClick={() => handleSetTab("sms")}>
                    School
                  </Link>
                </li>
                <li
                  className={` p-3 hover:bg-red gover:text-white text-base-grey transition-all cursor-pointer px-[5px] py-[14px] font-poppins ${
                    set === "aims" && " text-base-primary"
                  } `}
                >
                  <Link to="aims" onClick={() => handleSetTab("aims")}>
                    Accounting / Inventory
                  </Link>
                </li>
                <li
                  className={` p-3 hover:bg-red gover:text-white text-base-grey  transition-all cursor-pointer px-[5px] py-[14px] font-poppins ${
                    set === "ecom" && " text-base-primary"
                  } `}
                >
                  <Link to="ecom" onClick={() => handleSetTab("ecom")}>
                    E-Commerce
                  </Link>
                </li>
              </ul>

              <div className="flex flex-col gap-5 mt-10">
                <Link
                  className="underline items-center flex text-base-grey"
                  onClick={handleClickHome}
                >
                  Home
                  <img src={arrow} className="m-[-12px]" />
                </Link>
                <div
                  onClick={handleClick}
                  className="cursor-pointer font-poppins w-fit	flex items-center gap-2 bg-gradient-to-r from-dark-orange to-light-orange inline px-[14px] py-[8px] rounded-full text-white font-bold text-lg"
                >
                  <i class="bx bx-envelope"></i>
                  Contact
                  <i class="bx bx-right-arrow-alt bg-white p-1 rounded-full text-black"></i>
                </div>
              </div>
            </section>
          </div>
        )}
      </header>

      <main className=" w-full flex-1 bg-gradient-to-b from-blue-50 via-purple-50 to-indigo-50">
        <section className=" main w-[75%] mx-auto mt-[5%] mb-[2%]">
          <div className="highlight font-poppins bg-gradient-to-r from-dark-orange to-light-orange inline px-[22px] py-[7px] rounded-full text-white font-bold text-sm">
            WHAT WE OFFER
          </div>
          <h1 className="heading text-5xl font-bold font-poppins text-heading mt-[1.6%] mb-[1.6%]">
            Services
          </h1>
          <ul className="flex flex-wrap">
            {set === "hospital" &&
              tabs.map((itm, index) => (
                <li
                  onClick={() => setHospitalTabs(itm.name)}
                  className={`cursor-pointer px-2 pb-6 list-none text-base-grey ${
                    hospitalTabs === itm.name && "text-base-primary  font-bold"
                  }`}
                  key={index}
                >
                  {itm.name}
                </li>
              ))}
            {set === "ecom" &&
              ecomTab.map((itm, index) => (
                <li
                  onClick={() => setEcommerceTabs(itm.name)}
                  className={`cursor-pointer px-2 pb-6 list-none text-base-grey ${
                    ecommerceTabs === itm.name && "text-base-primary  font-bold"
                  }`}
                  key={index}
                >
                  {itm.name}
                </li>
              ))}
          </ul>

          <div className="min-h-[400px]">
            {/* Render cards or sections */}
            <Outlet />
          </div>
        </section>
      </main>

      <footer className="pt-10 w-full m-h-[30%] bg-footer flex flex-col justify-start items-center pt-2 px-4">
        <h1 className="font-poppins font-medium tracking-wide text-lg text-white">
          Designed by{" "}
          <a href="https://qo-box.com" target="_blank">
            <strong className="bg-grad-to-r bg-clip-text text-transparent tracking-normal">
              QO-BOX
            </strong>
          </a>
        </h1>
        <hr className="bg-primary w-full mt-2 opacity-40" />
        <div className="w-full flex justify-between items-center my-4 ">
          <div className="w-1/3 text-start text-base text-white font-poppins font-medium ">
            <span className="hover:bg-grad-to-r hover:bg-clip-text hover:text-transparent">
              © 2024 All rights reserved
            </span>
          </div>
          <div className="w-1/3 flex justify-end items-center gap-6 px-4 text-base text-start text-white font-poppins font-medium">
            <span className=" font- hover:bg-grad-to-r hover:bg-clip-text hover:text-transparent">
              <a href="https://qo-box.com/privacy-policy.html" target="_blank">
                Privacy policy
              </a>
            </span>
            <span className=" hover:bg-grad-to-r hover:bg-clip-text hover:text-transparent">
              <a href="https://qo-box.com/cookies.html" target="_blank">
                Cookies
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}
