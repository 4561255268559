import { Routes, Route } from "react-router-dom";
import "./App.css";

import {
  flutterhmsImage,
  phphmsMini,
  phphmsFull,
  phpDocAppoint,
  flutterhmsDemo,
  phphmsFullDemo,
  phpClinicms,
  phpDentalms,
  phppmsShop,
  phppmsAgency,
  laravelPharmaDemo,
  flutterLab,
  phpgym,
  phpMembership,
  flutterhrms,
  phphrms,
  phpSchoolMini,
  flutterSchoolms,
  phpSchoolFull,
  AcconInvenms,
  ecommerceAdmin,
  ecommerceSeller,
  ecommerceCustomer,
} from "./assets/Images.js";

import Home from "./pages/Home";
import SectionRenders from "./components/SectionRenders";
import NotFound from "./NotFound";

import { useState } from "react";

function App() {
  const [hospitalTabs, setHospitalTabs] = useState("All");
  const [set, setTab] = useState("hospital");
  const [ecommerceTabs, setEcommerceTabs] = useState("Flutter");
  const hms = [
    {
      title: "Flutter HMS",
      content:
        "A comprehensive hospital management system built using Flutter, offering cross-platform support for managing patients, appointments, billing, and more.",
      url: "https://flutter-hms.qualityoutsidethebox.info/",
      logo: flutterhmsImage,
      color: "#4c51bf",
    },
    {
      title: "PHP HMS full",
      content:
        "A fully-featured hospital management system developed in PHP, designed to manage all aspects of hospital operations, including patient records, staff management, and financial reports.",
      url: "https://php-hms-full.qualityoutsidethebox.info/",
      logo: phphmsFull,
      color: "#2b6cb0",
    },
    {
      title: "PHP HMS Mini",
      content:
        "A lightweight version of the hospital management system, ideal for small clinics or medical centers, providing essential functionalities like patient management and appointment scheduling.",
      url: "https://php-hms-mini.qualityoutsidethebox.info/",
      logo: phphmsMini,
      color: "#2c7a7b",
    },
    {
      title: "PHP Doc Appoint",
      content:
        "A PHP-based application focused on streamlining doctor appointment bookings, allowing patients to schedule appointments with ease and clinics to manage bookings efficiently.",
      url: "https://php-doctor-appointment-ms.qualityoutsidethebox.info/",
      logo: phpDocAppoint,
      color: "#805ad5",
    },
    {
      title: "Flutter HMS Demo",
      content:
        "A demo version of the Flutter-based hospital management system, showcasing the key features and functionalities for managing hospital workflows on mobile and web platforms.",
      url: "https://hms.infyom.com/",
      logo: flutterhmsDemo,
      color: "#4c51bf",
    },
    {
      title: "PHP HMS Full Demo",
      content:
        "A demo version of the complete PHP-based hospital management system, offering an interactive preview of the full range of hospital management features and capabilities.",
      url: "https://demo.smart-hospital.in/",
      logo: phphmsFullDemo,
      color: "#2b6cb0",
    },
  ];
  const cms = [
    {
      title: "Clinic Management System ",
      content:
        "A comprehensive Clinic Management System designed to streamline operations, manage patient records, appointments, billing, and more. Our solution includes both a user-friendly website and a mobile app, enabling healthcare providers to efficiently manage their clinic from anywhere.",
      url: "https://php-clinic-management-system.qualityoutsidethebox.info/",
      logo: phpClinicms,
      color: "#2b6cb0",
    },
    {
      title: "Dental Clinic Management System ",
      content:
        "Our Dental Clinic Management System offers specialized tools to manage patient appointments, dental records, treatment plans, billing, and inventory. Available as a website and mobile app, this system ensures seamless and efficient dental clinic operations on the go.",
      url: "https://identsoft.ambitiousit.net/",
      logo: phpDentalms,
      color: "#805ad5",
    },
  ];

  const pms = [
    {
      title: "PHP Pharma Agency",
      content:
        "PHP Pharma Agency is your trusted partner in delivering custom digital solutions for the pharmaceutical industry. We design and develop websites and mobile apps tailored to meet the needs of pharmacies, healthcare providers, and pharmaceutical companies.",
      url: "https://php-pharma-agency.qualityoutsidethebox.info/",
      logo: phppmsAgency,
      color: "#c05621",
    },
    {
      title: "PHP Pharma Shop",
      content:
        "PHP Pharma Shop offers an all-in-one platform for pharmacies, featuring a user-friendly website and mobile app for seamless online shopping. Easily manage orders, track deliveries, and provide customers with quick access to medications and healthcare products.",
      url: "https://php-pharma-shop.qualityoutsidethebox.info/",
      logo: phppmsShop,
      color: "#319795",
    },
    {
      title: "Laravel Pharma Detailed Demo",
      content:
        "Explore a comprehensive demo of our Laravel-based pharma solution, where we showcase a fully functional website and mobile app. This platform is designed to streamline pharmaceutical services, offering features for product management, orders, and customer interactions—accessible both on the web and mobile for seamless user experience.",
      url: "https://demopharma.ayaantec.com/login",
      logo: laravelPharmaDemo,
      color: "#805ad5",
    },
  ];
  const lms = [
    {
      title: "Flutter LMS",
      content:
        "A comprehensive learning management system built with Flutter, offering a user-friendly website and mobile app for seamless access to courses, resources, and assessments, enhancing the educational experience for learners and instructors alike.",
      url: "https://flutter-lab.qualityoutsidethebox.info/",
      logo: flutterLab,
      color: "#c05621",
    },
    {
      title: "PHP Pharma Shop",
      content:
        "An online pharmacy platform developed with PHP, providing a responsive website and mobile app for easy browsing, ordering, and delivery of pharmaceuticals, ensuring convenient access to health products and services for customers.",
      url: "https://php-pharma-shop.qualityoutsidethebox.info/",
      logo: phppmsShop,
      color: "#319795",
    },
  ];
  const fitness = [
    {
      title: "PHP Gym MS",
      content:
        "A comprehensive solution for gyms, offering a website and app to manage memberships, schedules, payments, and member engagement, streamlining operations for fitness centers.",
      url: "https://php-gym-management-system.qualityoutsidethebox.info/",
      logo: phpgym,
      color: "#dd6b20",
    },
    {
      title: "PHP Membership MS",
      content:
        "An efficient platform for managing memberships, featuring a website and app that handle sign-ups, renewals, payments, and member communication, enhancing the user experience for organizations.",
      url: "https://php-membership.qualityoutsidethebox.info/",
      logo: phpMembership,
      color: "#5a67d8",
    },
  ];
  const hrms = [
    {
      title: "Flutter HRMS",
      content:
        "A comprehensive Human Resource Management System (HRMS) built with Flutter, offering a user-friendly mobile app and website for efficient employee management, payroll processing, and performance tracking.",
      url: "https://demo.24hourworx.com/",
      logo: flutterhrms,
      color: "#c05621",
    },
    {
      title: "PHP HRMS",
      content:
        "An advanced Human Resource Management System (HRMS) developed in PHP, featuring a responsive website and app for streamlined employee management, recruitment, and payroll functions, enhancing organizational efficiency.",
      url: "https://php-hrms.qualityoutsidethebox.info/",
      logo: phphrms,
      color: "#3182ce",
    },
  ];
  const sms = [
    {
      title: "Flutter School MS",
      content:
        "A comprehensive Flutter-based School Management System offering an intuitive app and website, streamlining administrative tasks, student management, and communication between teachers, students, and parents.",
      url: "https://demo.smart-school.in/",
      logo: flutterSchoolms,
      color: "#3182ce",
    },
    {
      title: "PHP School Ms Full",
      content:
        "A robust PHP School Management System designed to enhance educational administration. It features a full website and app to manage student data, staff, classes, and communications effectively.",
      url: "https://infixedu.infixlive.com/",
      logo: phpSchoolFull,
      color: "#3182ce",
    },
    {
      title: "PHP School MS Mini",
      content:
        "A streamlined PHP School Management System Mini, providing essential features for efficient school administration through a simple website and app, perfect for smaller institutions or specific educational needs.",
      url: "https://php-school-management-system.qualityoutsidethebox.info/",
      logo: phpSchoolMini,
      color: "#805ad5",
    },
  ];
  const aims = [
    {
      title: "Accounting / Inventory MS ",
      content:
        "A comprehensive solution for managing finances and inventory seamlessly. Our platform includes a user-friendly website and app, enabling businesses to track expenses, manage stock, and generate reports efficiently.",
      url: "https://acculance.codeshaper.net/login",
      logo: AcconInvenms,
      color: "#dd6b20",
    },
  ];
  const ecomflutter = [
    {
      title: "Flutter Ecommerce Admin Panel",
      content:
        "Manage your e-commerce platform efficiently with the Flutter Ecommerce Admin Panel. This user-friendly interface provides tools for product management, order tracking, and user analytics, available as both a website and an app.",
      url: "https://cartuser.kodepixel.com/admin",
      logo: ecommerceAdmin,
      color: "#dd6b20",
    },
    {
      title: "Flutter Ecommerce Seller Panel",
      content:
        "Empower sellers with the Flutter Ecommerce Seller Panel, enabling easy product listing, order management, and sales tracking. This comprehensive solution is accessible via both a website and an app for seamless operation.",
      url: "https://cartuser.kodepixel.com/seller ",
      logo: ecommerceSeller,
      color: "#3182ce",
    },
    {
      title: "Flutter Ecommerce Customer Panel",
      content:
        "Enhance shopping experiences with the Flutter Ecommerce Customer Panel. Customers can browse products, track orders, and manage their profiles effortlessly through an intuitive interface available as both a website and an app.",
      url: "https://cartuser.kodepixel.com",
      logo: ecommerceCustomer,
      color: "#dd6b20",
    },
  ];
  const ecomphp = [
    {
      title: "PHP Ecommerce Admin Panel",
      content:
        "A comprehensive dashboard for managing all aspects of your e-commerce business, including product management, order tracking, and customer interactions, accessible via web and mobile apps.",
      url: "https://demo.readyecommerce.app/admin/login",
      logo: ecommerceAdmin,
      color: "#dd6b20",
    },
    {
      title: "PHP Ecommerce Seller Panel",
      content:
        "Empower sellers with a user-friendly interface to manage their products, track sales, and handle orders efficiently, available as both a website and mobile app.",
      url: "https://demo.readyecommerce.app/shop/login",
      logo: ecommerceSeller,
      color: "#dd6b20",
    },
    {
      title: "PHP Ecommerce Customer Panel",
      content:
        "An intuitive platform for customers to browse products, place orders, and manage their accounts seamlessly, offered through both a web interface and a mobile application.",
      url: "https://demo.readyecommerce.app/",
      logo: ecommerceCustomer,
      color: "#dd6b20",
    },
  ];

  const hospital = [...hms, ...pms, ...cms, ...lms];
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            setHospitalTabs={setHospitalTabs}
            hospitalTabs={hospitalTabs}
            set={set}
            setTab={setTab}
            ecommerceTabs={ecommerceTabs}
            setEcommerceTabs={setEcommerceTabs}
          />
        }
      >
        <Route
          index
          element={
            <SectionRenders
              cards={
                hospitalTabs === "All"
                  ? hospital
                  : hospitalTabs === "Hospital"
                  ? hms
                  : hospitalTabs === "Clinic"
                  ? cms
                  : hospitalTabs === "Laboratory"
                  ? lms
                  : pms
              }
            />
          }
        />

        <Route path="hms" element={<SectionRenders cards={hms} />} />
        <Route path="pms" element={<SectionRenders cards={pms} />} />
        <Route path="cms" element={<SectionRenders cards={cms} />} />
        <Route path="lms" element={<SectionRenders cards={lms} />} />
        <Route path="fitness" element={<SectionRenders cards={fitness} />} />
        <Route path="hrms" element={<SectionRenders cards={hrms} />} />
        <Route path="sms" element={<SectionRenders cards={sms} />} />
        {/*<Route path="ecom" element={
        <ComingSoon  
          setHospitalTabs={setHospitalTabs} 
          hospitalTabs = {hospitalTabs}
          set={set}

        />} />*/}
        <Route
          path="ecom"
          element={
            <SectionRenders
              cards={ecommerceTabs === "Flutter" ? ecomflutter : ecomphp}
            />
          }
        />
        <Route path="aims" element={<SectionRenders cards={aims} />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
